import { create } from "zustand";
import { createUserSlice } from "./slices/user";
import { createStrategySlice } from "./slices/strategy";
import { createFoctorsSlice } from "./btStudio/foctorsSlice";
import { createOtherSlice } from "./btStudio/otherSlice";
import { StoreState } from "@/types";

export const useStore = create<StoreState>((set, get, api) => ({
  ...createUserSlice(set, get, api),
  ...createStrategySlice(set, get, api),
  ...createFoctorsSlice(set, get, api),
  ...createOtherSlice(set, get, api),
}));
