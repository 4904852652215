import { listOfSignal } from "@/pages/BtStudio/components/BtsSideBar/components/BackTest/backtestService";
import { FoctorBase, FoctorIndicator } from "@/types";

export function intervalcount(v: string): number {
  const units: { [key: string]: number } = {
    m: 60 * 1000,
    h: 60 * 60 * 1000,
    d: 24 * 60 * 60 * 1000,
    w: 7 * 24 * 60 * 60 * 1000,
  };
  let unit: string = v.slice(-1);
  let value: number = parseInt(v.slice(0, -1), 10);
  return value * units[unit];
}

export const transformToShowLogicItems = (logicItems: any) => {
  return logicItems?.map((f: any) => {
    let foctor = f.foctor === "close" && f.from === "kline" ? "kline" : f.foctor?.replace(/_/g, " ");
    let params = {
      id: f.factorId,
      dataId: f.dataId,
      foctor,
      foctor_sourse: f.foctorSourse,
      interval: f.interval,
      symbol: f.coinId,
      symbolLabel: f.symbol,
      uniqueId: f.uniqueId,
    };

    // debugger
    switch (f.type) {
      case "foctor":
      case "kline":
      case "non_price_factor":
        return {
          ...params,
          type: "foctor",
          from: f.from,
        };
      case "price_layer":
      case "kline_layer":
      case "non_price_layer":
        return {
          ...params,
          type: "foctor",
          isLayer:true,
          from: f.from,
          layerId:f.layerId,
          targetLayerColName: f.targetLayerColName,
        };
      case "ind":
      case "kline_ind":
      case "non_price_ind":
        return {
          ...params,
          // foctor: f.factorId !== 5 ? f.foctor : "kline",
          foctor_id: f.factorId,
          foctor_ind: f.foctorInd,
          ind_payload: f.indPayload,
          sub_payload: f.subPayload,
          sub_payload_value: f.subPayloadValue,
          type: "ind",
          indId: f.indId,
          useScene: f.useScene,
          indicatorType: f.indicatorType,
          targetLayerColName: f.targetLayerColName,
          layerId:f.layerId,
        };
      case "logic_symbol":
      case "compute_symbol":
        return {
          MathSymbols: f.mathSymbols,
          id: `${f.type}_${f.mathSymbols}`,
          type: f.type,
          uniqueId: f.uniqueId,
        };
      case "contect_logic":
        return {
          ContectLogic: f.contectLogic,
          id: `${f.type}_${f.contectLogic}`,
          type: f.type,
          uniqueId: f.uniqueId,
        };
      case "number_input":
        return {
          type: f.type,
          uniqueId: f.uniqueId,
          value: f.value,
        };
      case "signal":
        return {
          uniqueId: f.uniqueId,
          ...listOfSignal.find((signal:any) => f.value == signal.value)
        }
      default:
        return {};
    }
  });
};

export const transformToSaveLogicItems = (items: any[], uniqueId: number) => {
  console.log('item',items)

  return items.map((item, index) => {
    const lastFactorSource = item.foctor_sourse?.[item.foctor_sourse.length - 1];
    let type, factorId = null;
    let foctor = item.foctor === "kline" ? "close" : item.foctor?.replace(/ /g, "_");
    console.log('item',item)
    if (lastFactorSource === "noPriceData") {
      factorId = item.id;
      if (item.type === "foctor") {
        type = "non_price_factor";
      } else {
        type = "non_price_ind";
      }
      
      if((typeof item.layerId == 'string')){
        type = 'non_price_layer';
      }
    }else if(lastFactorSource === 'priceData'){
      factorId = item.id;

      if (item.isLayer) {
        type = item.from === "kline" ? "kline_layer" : "price_layer";
      } else if (item.type === "foctor") {
        type = item.foctor === "kline" ? "kline" : "foctor";
      } else {
        type = item.from === "kline" ? "kline_ind" : "ind";
      }
    } else {
      type = item.type;
    }
  
    return {
      factorId,
      type,
      foctor,
      layerId: item.layerId,
      targetLayerColName: Array.isArray(item.targetLayerColName) ? item.targetLayerColName[0] : item.targetLayerColName,
      order: index + 1,
      coinId: item.symbol,
      symbol: item.symbolLabel,
      foctorSourse: item.foctor_sourse,
      foctorInd: item.foctor_ind,
      indPayload: item.ind_payload,
      subPayload: item.sub_payload,
      subPayloadValue: item.sub_payload_value,
      mathSymbols: item.MathSymbols,
      contectLogic: item.ContectLogic,
      uniqueId: uniqueId + index,
      dataId: item.dataId,
      interval: item.interval,
      value: item.value,
      indId: item.indId,
      useScene: item.useScene,
      from: item.from,
      vreturn: item.vreturn,
      indicatorType: item.indicatorType,
    };
  });
  
};
export const getFoctorObj = (field: any) => {
  return transformToShowLogicItems(field).filter(
    (f: any) => f.type === "foctor" || f.type === "ind"
  );
};

export const getFoctorIndicator = (foctor: any) => {};

export function transformData(arr1: any) {
  const result: any = [];

  arr1.forEach((item: any) => {
    const existing = result.find(
      (r: any) => r.foctor_id === item.factorId && r.symbol === item.coinId
    );

    if (!existing) {
      // 如果没有相同的 foctor_id 和 symbol 的对象，则创建一个新的
      const newItem = {
        foctor_id: item.factorId,
        symbol: item.coinId,
        foctor_sourse: item.foctorSourse,
        interval: item.interval,
        label: item.foctor,
        foctor: item.foctor,
        // color: item.type === "foctor" ? "#49FC67" : "#C755FC", // 根据 type 判断颜色
        indFlag: item.type === "foctor", // 如果是 'ind' 类型，则设置 indFlag 为 true
        foctor_indicator:
          item.type === "ind"
            ? [
                {
                  symbol: item.coinId,
                  foctor: item.foctor,
                  foctor_label: item.foctor,
                  foctor_id: item.factorId,
                  foctor_sourse: item.foctorSourse,
                  id: `${item.foctorInd}#${generateUniqueId()}`, // 生成一个唯一 ID
                  label: item.foctorInd,
                  interval: item.interval,
                  payload: item.indPayload || {},
                  vreturn: [item.foctorInd.toLowerCase()],
                  chart_area: "overlays",
                  chart_type: "line",
                  color: item.type === "ind" ? "#58F9D6" : "#FAF083", // 不同的指示器颜色
                },
              ]
            : [],
      };
      result.push(newItem);
    } else {
      // 如果已有相同的项（foctor_id 和 symbol 匹配），则需要将指示器添加到 foctor_indicator 中
      if (item.type === "ind") {
        existing.foctor_indicator.push({
          symbol: item.coinId,
          foctor: item.foctor,
          foctor_label: item.foctor,
          foctor_id: item.factorId,
          foctor_sourse: item.foctorSourse,
          id: `${item.foctorInd}#${generateUniqueId()}`, // 生成一个唯一 ID
          label: item.foctorInd,
          interval: item.interval,
          payload: item.indPayload || {},
          vreturn: [item.foctorInd.toLowerCase()],
          chart_area: "overlays",
          chart_type: "line",
          color: "#FAF083", // 默认指示器颜色
        });
      }
    }
  });

  return result;
}

// 生成唯一 ID
function generateUniqueId() {
  return Math.random().toString(36).substr(2, 9); // 简单生成一个唯一的 ID
}

export const transformKlineData = (arr: KlineDataProps[]) => {
  return arr.map((k: KlineDataProps) => [
    k.openTime,
    k.open,
    k.high,
    k.low,
    k.close,
  ]);
};

export const ohlcArr = ["open", "high", "low", "close"];

//
export function uniqueByKey(array: Record<string, unknown>[], key: string) {
  const map = new Map();
  return array?.filter((item) => {
    if (!map.has(item[key])) {
      map.set(item[key], true);
      return true;
    }
    return false;
  });
}

export const countInds = (indicators: FoctorBase[] = []) => {
  let count = 0;
  indicators.forEach((item) => {
    if (item.factor_layer) {
      count += item.factor_layer.length;
      count += countInds(item.factor_layer);
    }
  });
  return count;
};

export const flattenLayers = (layerArr: FoctorBase[]): any =>
  layerArr.flatMap((item) =>
    item.factor_layer ? [item, ...flattenLayers(item.factor_layer)] : [item]
  );

export const getLayerIndkeysList = (arr: FoctorIndicator[]) =>
  arr.reduce((pre: string[], cur): string[] => {
    const currentkeyArr = cur.vreturn.map(
      (v) => `${cur.foctor_id}#${cur.id}@${cur.color}@${cur.chart_area}^${v}`
    );
    return [...pre, ...currentkeyArr];
  }, []);

export const deleteItemById = (
  indicators: FoctorIndicator[] = [],
  idToDelete: string
) => {
  return indicators.filter((item) => {
    if (item.id === idToDelete) {
      return false;
    }

    if (item.factor_layer) {
      item.factor_layer = deleteItemById(item.factor_layer, idToDelete);
    }

    return true;
  });
};

export const replaceItemById = (indicators: any, id: string, newObj: any) => {
  return indicators.map((item: any) => {
    if (item.id === id) {
      return {
        ...item,
        factor_layer: [...(item.factor_layer || []), newObj],
      };
    }

    if (item.factor_layer) {
      return {
        ...item,
        factor_layer: replaceItemById(item.factor_layer, id, newObj), // 递归处理子元素
      };
    }

    return item;
  });
};

export const getChartDataName = (obj: FoctorBase | FoctorIndicator, index?: number) => {
  if('id' in obj){
    // FoctorIndicator
    return `${obj.foctor_id}#${obj.id}@${obj.color}@${obj.chart_area}^${obj["vreturn"][index ?? 0]}`
  }else{
    // FoctorBase
    return `${obj.foctor_id}`
  }
}

  // 递归查找最后一层的 factor_layer
  export const findDeepestLayer = (layer: any): any => {
    // 如果当前层没有 factor_layer 或 factor_layer 为空数组，则返回当前层
    if (!layer?.factor_layer || layer.factor_layer.length === 0) {
      return layer;
    }
    
    // 获取当前层的最后一个元素
    const lastLayer = layer.factor_layer[layer.factor_layer.length - 1];
    // 递归查找更深的层
    return findDeepestLayer(lastLayer);
  };