import { RoundedLoadingButton } from "@/components/basic";
import { LoginBox, LoginComponentProps, UnderlineLink } from "./Login";
import { Box, TextField, Typography, FormControlLabel, Checkbox, Link } from "@mui/material";
import { useState } from "react";
import { EmailRegisterParam } from "@/apis/model/user";
import { emailRegister } from "@/apis/user";
import { useStore } from "@/store";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
const SignUp: React.FC<LoginComponentProps> = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setUserInfo } = useStore();
  const [form, setForm] = useState<
    EmailRegisterParam & { confirmPassword: string }
  >({
    email: "",
    password: "",
    verifyCode: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    email: false,
    password: false,
    confirmPassword: false,
  });
  const [agreements, setAgreements] = useState({
    marketing: false,
    terms: false,
  });
  const handleSubmit = async () => {
    if (!agreements.marketing || !agreements.terms) {
      toast.error("Please accept all terms to continue");
      return false;
    }
    const _error = { ...error };
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email)) {
      _error.email = true;
    }
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*./_-])(?!.*\s).{8,}/.test(form.password)) {
      _error.password = true;
      _error.confirmPassword = true;
    }
    if (form.password !== form.confirmPassword) {
      _error.confirmPassword = true;
    }
    setError(_error);
    if (Object.values(_error).some(Boolean)) {
      return false;
    }
    setLoading(true);
    emailRegister(form)
      .then((res) => {
        setUserInfo({ ...res.data, email: form.email });
        // navigate("/bt-studio");
        window.location.reload();
      })
      .catch((err) => {
        err.msg && toast(err.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearError = () => {
    setError({
      email: false,
      password: false,
      confirmPassword: false,
    });
  };
  return (
    <LoginBox title='Sign Up'>
      {/* <RoundedButton
        sx={{
          mb: 2,
          textTransform: "none",
        }}
        fullWidth
        color='inherit'
        variant='outlined'
        startIcon={<GoogleIcon></GoogleIcon>}
      >
        <Box sx={{ fontSize: 13 }}>Sign in with Google</Box>
      </RoundedButton>
      <Divider sx={{ mb: 2 }}>OR</Divider> */}
      <Box component='form'>
        <TextField
          error={error.email}
          helperText={error.email ? "Invalid Email" : ""}
          sx={{ mb: 2 }}
          size='small'
          required
          fullWidth
          onChange={(event) => {
            setForm({
              ...form,
              email: event.target.value,
            });
            clearError();
          }}
          label='Email'
        ></TextField>
        <TextField
          error={error.password}

          onChange={(event) => {
            setForm({
              ...form,
              password: event.target.value,
            });
            clearError();
          }}
          sx={{ mb: 2 }}
          size='small'
          required
          type='password'
          fullWidth
          label='Password'
        ></TextField>
        
        <TextField
          error={error.confirmPassword}
          helperText={
            error.confirmPassword
              ? error.password && error.confirmPassword
                ? "The password must be at least 8 characters long and include uppercase and lowercase letters, numbers, and special characters."
                : "Passwords do not match"
              : ""
          }
          onChange={(event) => {
            setForm({
              ...form,
              confirmPassword: event.target.value,
            });
            clearError();
          }}
          sx={{ mb: 2 }}
          type='password'
          size='small'
          required
          fullWidth
          label='Confirm Password'
        ></TextField>
        <Box sx={{}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreements.marketing}
                onChange={(e) => setAgreements({ ...agreements, marketing: e.target.checked })}
              />
            }
            label={<div className="text-left" style={{fontSize: '14px'}}>I agree to receive marketing emails about products and services</div>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={agreements.terms}
                onChange={(e) => setAgreements({ ...agreements, terms: e.target.checked })}
              />
            }
            label={
              <Typography variant="body2" className="text-left" style={{fontSize: '14px'}}>
                I agree to the{" "}
                <Link href="/#/terms-conditions" color="primary" underline="hover">
                  Terms and Conditions
                </Link>{" "}
                and{" "}
                <Link href="#/privacy-policy" color="primary" underline="hover">
                  Privacy Policy
                </Link>
              </Typography>
            }
          />
        </Box>
        <RoundedLoadingButton
          variant='contained'
          fullWidth
          loading={loading}
          onClick={handleSubmit}
          sx={{ mt: 2, textTransform: "none" }}
        >
          Continue
        </RoundedLoadingButton>

        <Typography
          sx={{
            color: "",
            ":hover": {},
            mt: 2,
          }}
          align='center'
        >
          Already have an account?
          <UnderlineLink
            onClick={(event) => {
              event.preventDefault();
              props.onTypeChange && props.onTypeChange("login");
            }}
          >
            Log in
          </UnderlineLink>
        </Typography>
      </Box>
    </LoginBox>
  );
};
export default SignUp;
