import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { strategyInfoGet } from "@/apis/coin";
import { StrategyInfo } from "@/apis/model/coin";
import { transformToShowLogicItems } from "@/utils/tool";
import { StateCreator } from "zustand";
import { produce } from "immer";
import { StoreState, StrategyState  } from "@/types";
import qs from "qs";
dayjs.extend(utc);

export const createStrategySlice: StateCreator<
  StoreState,
  [],
  [],
  StrategyState
> = (set, get) => ({
  chartSvgInfo: "",
  chartData: {},
  dangeDate: [
    dayjs().subtract(1, "year").startOf("day").utc().format("YYYY-MM-DD HH:mm:ss"),
    dayjs().startOf("day").utc().format("YYYY-MM-DD HH:mm:ss"),
  ],
  strategyInfo: {},
  entriesLogicItems: [],
  exitsLogicItems: [],
  clearStrategyLogic: false,
  setChartSvgInfo: (info) =>
    set(
      produce((state: StrategyState) => {
        state.chartSvgInfo = info;
      })
    ),
  setChartData: (data) =>
    set(
      produce((state: StrategyState) => {
        state.chartData = { ...state.chartData, ...data };
      })
    ),
  deleteChartData: (prefix) =>
    set(
      produce((state: StrategyState) => {
        const newChartData = { ...state.chartData };
        for (let key in newChartData) {
          if (key.startsWith(prefix)) {
            delete newChartData[key];
          }
        }
        state.chartData = newChartData;
      })
    ),
  setStrategyInfo: (info) =>
    set(
      produce((state: StrategyState) => {
        state.strategyInfo = {
          ...info,
          strategyConfig: {
            ...info.strategyConfig,
            entriesLogicItems: transformToShowLogicItems( info.strategyConfig?.entriesLogicItems ),
            exitsLogicItems: transformToShowLogicItems(info.strategyConfig?.exitsLogicItems),
          },
        };
      })
    ),
  setEntriesLogicItems: (entriesLogicItems) =>
    set(
      produce((state: StrategyState) => {
        if (Array.isArray(entriesLogicItems)) {
          state.entriesLogicItems = entriesLogicItems;
        } else {
          state.entriesLogicItems = [
            ...state.entriesLogicItems,
            entriesLogicItems,
          ];
        }
      })
    ),
  setExitsLogicItems: (exitsLogicItems) =>
    set(
      produce((state: StrategyState) => {
        if (Array.isArray(exitsLogicItems)) {
          state.exitsLogicItems = exitsLogicItems;
        } else {
          state.exitsLogicItems = [...state.exitsLogicItems, exitsLogicItems];
        }
      })
    ),
  resetStrategyEntriesAndExits: (type) =>
    set(
      produce((state: StrategyState) => {
        if (type === "entry") {
          state.entriesLogicItems = [];
        } else if (type === "exit") {
          state.exitsLogicItems = [];
        } else {
          state.entriesLogicItems = [];
          state.exitsLogicItems = [];
        }

        if (state.strategyInfo?.strategyConfig) {
          if (type === "entry") {
            state.strategyInfo.strategyConfig.entriesLogicItems = [];
          } else if (type === "exit") {
            state.strategyInfo.strategyConfig.exitsLogicItems = [];
          } else {
            state.strategyInfo.strategyConfig.entriesLogicItems = [];
            state.strategyInfo.strategyConfig.exitsLogicItems = [];
          }
        }
        state.clearStrategyLogic = true;
      })
    ),
  setClearStrategyLogic: (clear) =>
    set(
      produce((state: StrategyState) => {
        state.clearStrategyLogic = clear;
      })
    ),
  resetStrategy: () =>
    set(
      produce((state: StrategyState) => {
        return Object.assign(state, {
          chartSvgInfo: "",
          chartData: {},
          dangeDate: [
            dayjs().subtract(1, "year").startOf("day").utc().format("YYYY-MM-DD HH:mm:ss"),
            dayjs().startOf("day").utc().format("YYYY-MM-DD HH:mm:ss")
          ],
          strategyInfo: {},
          entriesLogicItems: [],
          exitsLogicItems: [],   
          clearStrategyLogic: false,
          classVars: null,
        });
      })
    ),
  setDangeDate: (date) =>
    set(
      produce((state: StrategyState) => {
        state.dangeDate = date;
      })
    ),
  setLayerInd: (ind) =>
    set(
      produce((state: StrategyState) => {
        if (qs.stringify(state.layerInd) !== qs.stringify(ind)) {
          state.layerInd = ind;
        }
      })
    ),
  fetchStrategyInfo: async (params:StrategyInfo) => {
    try {
      const response = await strategyInfoGet(params);
      // @ts-ignore
      const { strategyConfig, priceDataQueryParam, classVars } = response.data;
      // 更新 Zustand 状态
      set(
        produce((state) => {
          // 这个数据应该还有问题的
          const entriesLogicItems = transformToShowLogicItems(strategyConfig?.entriesLogicItems);
          const exitsLogicItems = transformToShowLogicItems(strategyConfig?.exitsLogicItems);
          console.log(entriesLogicItems,'回显的策略');
          state.interval = strategyConfig.interval;
          state.dangeDate = [strategyConfig.startDate, strategyConfig.endDate];
          state.entriesLogicItems = entriesLogicItems;
          state.exitsLogicItems = exitsLogicItems;
          state.strategyInfo = {
            ...response.data,
            priceDataQueryParam,
            strategyConfig: {
              ...strategyConfig,
              entriesLogicItems,
              exitsLogicItems,
            },
          };
          state.classVars = classVars;
        })
      );
    } catch (error) {
      console.error("Failed to fetch strategy info:", error);
    }
  },
});
