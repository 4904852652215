import { emailLogin, getUserInfo } from "../../apis/user";
import { StateCreator } from "zustand";
import { produce } from "immer";
import { UserState, StoreState } from "@/types";

export const createUserSlice: StateCreator<StoreState, [], [], UserState> = (
  set,
  get
) => {
  return {
    accessToken: localStorage.getItem("accessToken") || "",
    refreshToken: localStorage.getItem("refreshToken") || "",
    userInfo: null,
    // 没用到   
    userLogin: async (args) => {
      try {
        const response = await emailLogin(args);
        const data = response.data;
        get().setUserInfo(data);
      } catch (error) {
        console.error(error);
      }
    },
    loadUserInfo: async () => {
      try {
        const response = await getUserInfo(); 
        const data = response.data;
        get().setUserInfo(data);
      } catch (error) {
        console.error(error);
      }
    },
    setUserInfo: (userInfo) =>
      set(
        produce((state) => {
          state.userInfo = userInfo;
        })
      ),
    setToken: ({ accessToken, refreshToken }) =>
      set(
        produce((state) => {
          if (accessToken) {
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
          }
        })
      ),
    clearUserInfo: () => {
      set(
        produce((state) => {
          state.userInfo = null;
          state.accessToken = "";
          state.refreshToken = "";
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
        })
      );
    },
  };
};
