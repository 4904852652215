import { getNavigate } from "@/utils";
import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import toast from "react-hot-toast";
import qs from "qs";
import { useStore } from '@/store'
const request = axios.create({
  baseURL: process.env.REACT_APP_URL,
  timeout: 100000,
});

// 维护进行中的请求（仅存储内部创建的 AbortController）
const pendingRequests = new Map<string, AbortController>();

// 生成唯一请求 key
const getRequestKey = (config: InternalAxiosRequestConfig) => {
  const { method, url, params, data } = config;
  const sortedParams = params ? qs.stringify(params, { sort: (a, b) => a.localeCompare(b) }) : "";
  const sortedData = data ? JSON.stringify(Object.entries(data).sort()) : "";
  return `${method}#${url}?${sortedParams}${sortedData}`;
};

const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  const requestKey = getRequestKey(config);

  // **如果已有相同请求，先取消**
  if (pendingRequests.has(requestKey)) {
    pendingRequests.get(requestKey)?.abort();
    pendingRequests.delete(requestKey);
  }

  // **支持外部传入 signal**
  if (!config.signal) {
    const controller = new AbortController();
    config.signal = controller.signal;
    pendingRequests.set(requestKey, controller); // 仅存储内部创建的 controller
  }

  // 处理请求头
  config.headers["tenant-id"] = 1;
  const accessToken = useStore.getState().accessToken;
  if (accessToken) {
    config.headers["Authorization"] = accessToken;
  }
  return config;
};
const responseInterceptor = (response: AxiosResponse) => {
  const requestKey = getRequestKey(response.config);
  pendingRequests.delete(requestKey); // 请求完成，移除 key

  if (response.status === 200) {
    if (response.data.code === 401) {
      useStore.getState().clearUserInfo();
      const navigate = getNavigate();
      navigate && navigate("/login");
    } else if (response.data && response.data.code === 0) {
      return response.data;
    } else {
      toast.error(response.data.msg || "Request Failed");
      throw response.data;
    }
  } else {
    toast.error("Network Error");
    throw new Error("Network Error");
  }
};

// **拦截请求**
request.interceptors.request.use(requestInterceptor);
// **拦截响应**
request.interceptors.response.use(responseInterceptor, (error) => {
  if (axios.isCancel(error)) {
    console.log("Request aborted:", error.message);
    return new Promise(() => {});
  } else {
    toast.error(error.message || "Request Failed");
  }

  const requestKey = error.config ? getRequestKey(error.config) : "";
  pendingRequests.delete(requestKey); // 确保异常请求也能清除
  return Promise.reject(error);
});

// **取消指定 method + url 的所有请求**
export const cancelRequestByUrl = (method: string, url: string) => {
  const keysToCancel = Array.from(pendingRequests.keys()).filter((key) =>
    key.startsWith(`${method}#${url}?`)
  );
  keysToCancel.forEach((key) => {
    pendingRequests.get(key)?.abort();
    pendingRequests.delete(key);
  });
  console.log(`Canceled all requests for ${method} ${url}`);
};

// **精准取消特定 method + url + params/data 的请求**
export const cancelSpecificRequest = (method: string, url: string, paramsOrData?: any) => {
  const sortedParams = paramsOrData ? qs.stringify(paramsOrData, { sort: (a, b) => a.localeCompare(b) }) : "";
  const requestKey = `${method}#${url}?${sortedParams}`;

  if (pendingRequests.has(requestKey)) {
    pendingRequests.get(requestKey)?.abort();
    pendingRequests.delete(requestKey);
    console.log(`Canceled request: ${requestKey}`);
  } else {
    console.log(`Request not found: ${requestKey}`);
  }
};

// 获取所有进行中的请求
export const getProcessRequest = () => {
  return pendingRequests;
};

export default request;
