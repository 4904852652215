import { memo } from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <footer className='h-52 max-md:h-32 max-md:text-sm flex items-center border-t border-[#E7E9EA1A] text-[#8A8A8A]'>
      <div className='container max-md:flex-col max-md:items-center flex justify-between mx-auto  max-w-[1280px] px-5'>
        <div className='flex justify-center max-sm:flex-col'>
          <img
            alt=''
            className='w-20 max-sm:mx-auto max-sm:mb-2'
            src='/headlogo.svg'
          />
          <div className='mx-9 max-sm:hidden'>|</div>
          <div className=''>@2024 Corr.ai All rights reserved</div>
        </div>
        <div className='flex items-center max-md:mt-4 max-sm:mt-2'>
          <Link
            to={"/privacy-policy"}
            className=' cursor-pointer hover:underline'
          >
            Privacy Policy
          </Link>
          <div className='w-[5px] h-[5px] bg-[#DDDDDD] rounded-full mx-4'></div>
          <Link
            to={"/terms-conditions"}
            className=' cursor-pointer hover:underline'
          >
            Terms & Conditions
          </Link>
        </div>
      </div>
    </footer>
  );
};
export default memo(Footer);
