import { Navigate, useLocation } from "react-router-dom";
import { searchRoute } from ".";
import { rootRouter } from "..";
import { useStore } from "@/store";

const ProtectedRouter = ({ children }: { children: JSX.Element }) => {
  const { pathname } = useLocation();
  const route = searchRoute(pathname, rootRouter);
  if (!route.meta?.requiresAuth) return children;
  const token = useStore.getState().accessToken;
  return !token ? <Navigate to='/login' replace /> : children;
};
export default ProtectedRouter;
