import { StateCreator } from "zustand";
import { produce } from "immer";
import { OtherState, StoreState } from "@/types";
import { fetchChatGPT } from "@/apis/coin";
import qs from 'qs';

export const createOtherSlice: StateCreator<StoreState, [], [], OtherState> = (
  set,
  get
) => {
  return {
    assets: [],
    symbol: "",
    cacheKeyMap: {},
    colKey: "",
    corrGPT: false,
    corrGPTLoading: false,
    interval: "",
    foctorsIndicator: {},
    listInterval: [],
    messages: [],
    isInit: false,
    sidebarType: "data_sidebar",
    ChatSessionId: null,
    createBackTestData: [],
    backtestLoading: false,
    selectChart: "chart",
    sidebarInfo:{//如果数据是这样，说明要跳回到添加foctor界面
      type:'foctor',
      foctorId:'',
      foctorSourse:['priceData']
    },

    setSymbol: (symbol) =>
      set(
        produce((state: OtherState) => {
          state.symbol = symbol;
        })
      ),
    setAssets: (assets) =>
      set(
        produce((state: OtherState) => {
          state.assets = assets;
        })
      ),

    symbolLabel: (symbol = get().symbol) => {
      return (
        get().assets.find(
          (item) => parseInt(item.metaId, 10) === parseInt(symbol, 10)
        )?.symbol || ""
      );
    },
    setCacheKey: (key, value) =>
      set(
        produce((state: OtherState) => {
          state.cacheKeyMap[key] = value;
        })
      ),
    delCacheKey: (key) =>
      set(
        produce((state: OtherState) => {
          delete state.cacheKeyMap[key];
        })
      ),
    setColKey: (colKey) =>
      set(
        produce((state: OtherState) => {
          state.colKey = colKey;
        })
      ),
    setcorrGPT: (val) =>
      set(
        produce((state: OtherState) => {
          state.corrGPT = val;
        })
      ),

    setCorrGPTLoading: (val) =>
      set(
        produce((state: OtherState) => {
          state.corrGPTLoading = val;
        })
      ),

    setInterval: (val) =>
      set(
        produce((state: OtherState) => {
          state.interval = val;
        })
      ),
    setSidebarInfo: (info)=>
      set(
        produce((state: OtherState)=>{
          if (qs.stringify(state.sidebarInfo) !== qs.stringify(info)) {
            state.sidebarInfo = info;
          }
        })
      ),
    setfoctorsIndicator: (foctor) =>
      set(
        produce((state: OtherState) => {
          state.foctorsIndicator = foctor;
        })
      ),

    setListInterval: (list) =>
      set(
        produce((state: OtherState) => {
          state.listInterval = list;
        })
      ),
    addMessage: (
      role: "user" | "assistant",
      questionType: string,
      content: string
    ) => {
      set(
        produce((state: OtherState) => {
          state.messages = [...state.messages, { role, questionType, content }];
        })
      );
    },
    setMessages: (messages) =>
      set(
        produce((state: OtherState) => {
          state.messages = messages;
        })
      ),
    askGPT: async (question, questionType) => {
      if (!question.trim()) return;

      // 先同步更新状态，添加用户消息 & 设置加载状态
      set((state) =>
        produce(state, (draft) => {
          draft.addMessage("user", questionType, question);
          draft.setCorrGPTLoading(true);
        })
      );

      try {
        const response = await fetchChatGPT({
          factor_data: question,
          type: questionType,
        });

        const data = response.data;
        console.log("data", data);

        // 这里再更新状态，添加 AI 消息 & 关闭加载状态
        set((state) =>
          produce(state, (draft) => {
            draft.addMessage("assistant", questionType, data);
            draft.setCorrGPTLoading(false);
          })
        );

        console.log("done");
      } catch (error) {
        console.error("请求失败:", error);

        // 发生错误时，更新状态
        set((state) =>
          produce(state, (draft) => {
            draft.setCorrGPTLoading(false);
            draft.addMessage(
              "assistant",
              "error_message",
              "An error occurred while processing your request. Please try again later."
            );
          })
        );
      }
    },
    setIsInit: () => {
      set(
        produce((state: OtherState) => {
          state.isInit = true;
        })
      );
    },
    setSidebarType: (sidebarType) =>
      set(
        produce((state: OtherState) => {
          state.sidebarType = sidebarType;
        })
      ),
    showDataSidebar: () => {
      if(get().sidebarType === "data_sidebar") return;
      set(
        produce((state: OtherState) => {
          state.sidebarType = "data_sidebar";
        })
      )
    },
    setChatSessionId: (ChatSessionId) =>
      set(
        produce((state: OtherState) => {
          state.ChatSessionId = ChatSessionId;
        })
      ),
    setCreateBackTestData: (createBackTestData) =>
      set(
        produce((state: OtherState) => {
          state.createBackTestData = createBackTestData;
        })
      ),
    setbacktestLoading: (backtestLoading) =>
      set(
        produce((state: OtherState) => {
          state.backtestLoading = backtestLoading;
        })
      ),
    setSelectChart: (selectChart) =>
      set(
        produce((state: OtherState) => {
          state.selectChart = selectChart;
        })
      ),
    resetOtherSliceState: () => {
      set(
        produce((state: OtherState) => {
          state.assets = [];
          state.symbol = "";
          state.cacheKeyMap = {};
          state.corrGPT = false;
          state.corrGPTLoading = false;
          state.interval = "";
          state.sidebarInfo = {
            type:'foctor',
            foctorId:'',
            foctorSourse:['priceData']
          };
          state.foctorsIndicator = {};
          state.listInterval = [];
          state.messages = [];
          state.isInit = false;
          state.sidebarType = "data_sidebar";
          state.ChatSessionId = null;
          state.createBackTestData = [];
          state.backtestLoading = false;
          state.selectChart = "chart";
        })
      );
    },
  };
};
