import { useStore } from "@/store";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";


const Header: React.FC = () => {
  const navigate = useNavigate();
  const handleHomePage = () => {
    navigate("/");
  };
  const handleLogin = () => {
    navigate("/login");
  };
  const{ accessToken } = useStore();
  
  return (
    <div className="home_header">
      <div className="flex justify-between w-full mb-10 md:mb-28 h-12 mt-7">
        <img
          width={150}
          src="/headlogo.svg"
          alt="Logo"
          onClick={handleHomePage}
          style={{ cursor: "pointer" }}
        />

        {!accessToken && (
          <Button
            onClick={handleLogin}
            variant="outlined"
            sx={{
              color: "#E7E9EA",
              width: "99px",
              borderRadius: "50px",
              borderColor: "#5D5D5D",
              '&:hover': {
                borderColor: "#FFAC06",
                backgroundColor: "#FFAC0690"
              }
            }}
          >
            Log in
          </Button>
        )}
      </div>
    </div>
  );
};

export default Header;
