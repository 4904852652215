import { createBackTest } from "@/apis/coin";
import axios from "axios";
import { MathSymbolType, ContectLogicType,SignalType } from "./model";
import { FormSchemaType } from "./Common/schema";

interface BacktestPayload extends FormSchemaType {
  // backtestStyle: string;
  entriesLogicItems: any[];
  exitsLogicItems: any[];
  startDate: string;
  endDate: string;
  interval: string;
}

interface SaveBacktestPayload {
  jwt: string | null;
}

export const createBacktest = async (
  payload: { 
    strategyConfig: BacktestPayload,
    classVariables: Record<string, any>
  },
  setCreateBackTestError: (error: boolean) => void,
  setCreateBackTestErrorMessage: (message: string) => void,
  setCreateBackTestData: (data: any) => void,
  CreateSaveBacktest: (state: boolean) => void,
  setSelectChart: (chart: string) => void
) => {
  try {
    // 根据tradingSymbol自动设置y_list
    if (payload.strategyConfig.btSymbol) {
      const symbol = payload.strategyConfig.btSymbol;
  
      const tradesFrees =  payload.strategyConfig.tradesFrees;
      const tradeStyle = payload.strategyConfig.tradeStyle;
      // 确保classVariables存在
      if (!payload.classVariables) {
        payload.classVariables = {};
      }
      
      // 设置y_list
      
      payload.classVariables.fee = tradesFrees;
      payload.classVariables.max_open_trades = 1;
      payload.classVariables.pair_whitelist = [`${symbol.toLocaleUpperCase()}/USDT`];
      if (tradeStyle !== 0) {
        payload.classVariables.can_short = 'True';
        payload.classVariables.trading_mode = 'futures';
        payload.classVariables.pair_whitelist = [`${symbol.toLocaleUpperCase()}/USDT:USDT`];
      }
    
    }

    console.log("==========后端API调用数据===========");
    console.log("完整请求payload:", JSON.stringify(payload, null, 2));
    console.log("==================================");
    
    const response = await createBackTest(payload);

    if (response.data){
      console.log("后端API响应数据:");
      console.log(response.data);
    }
    
    if (response.data) {
      const backtest_result = response.data;

      // 判断回测数据格式 - 检查是否为直接API格式
      const isDirectApiFormat = backtest_result && 
                              typeof backtest_result === 'object' && 
                              'trades' in backtest_result &&
                              'total_trades' in backtest_result;
      
      console.log("数据格式检测:", isDirectApiFormat ? "直接API格式" : "其他格式");
      
      if (isDirectApiFormat) {
        // 直接API格式的数据 - 包装成数组传递
        console.log("使用直接API格式数据");
        setCreateBackTestData([backtest_result]);
      } else {
        // 处理其他格式的数据
        console.log("使用兼容模式处理数据");
        
        // 确保 backtest_result 是一个数组
        let dataToSet;
        
        if (Array.isArray(backtest_result)) {
          dataToSet = backtest_result;
        } else if (backtest_result && typeof backtest_result === 'object') {
          // 如果不是数组但是是对象，尝试包装成数组
          dataToSet = [backtest_result];
        } else {
          // 如果既不是数组也不是对象，使用空数组
          console.warn("回测数据格式异常:", backtest_result);
          dataToSet = [];
        }
        
        setCreateBackTestData(dataToSet);
      }
      
      // 切换到回测结果视图
      setSelectChart("backtest_result");
      setCreateBackTestErrorMessage("");
      setCreateBackTestError(false);
      CreateSaveBacktest(false);
      
      // 如果有策略ID则返回
      return response.data.strategy_id || false;
    }

    setCreateBackTestError(false);
    setCreateBackTestErrorMessage("");
    return false;
  } catch (error: any) {
    console.error("回测过程发生错误:", error);
    
    setCreateBackTestError(true);
    if (error && error.response && error.response.data && error.response.data.message) {
      // 处理API返回的错误消息
      setCreateBackTestErrorMessage(error.response.data.message);
    } else if (error && error.msg) {
      // 处理error对象中的msg
      setCreateBackTestErrorMessage(error.msg);
    } else if (error && error.message) {
      // 处理error对象中的message
      setCreateBackTestErrorMessage(error.message);
    } else {
      // 默认错误消息
      setCreateBackTestErrorMessage("回测过程发生错误，请检查您的策略设置或稍后重试");
    }
    
    return false;
  }
};

export const createSaveBacktest = async (
  payload: SaveBacktestPayload,
  setCreateBackTestError: (error: boolean) => void,
  setCreateBackTestErrorMessage: (message: string) => void,
  setCreateSaveBacktest: (state: boolean) => void
) => {
  try {
    const response = await axios.post(
      "http://localhost:8000/post_save_backtest",
      payload
    );

    const savebacktest_result_message = response.data.message;
    if (savebacktest_result_message == "Strategy saved successfully") {
      setCreateSaveBacktest(true);
      setCreateBackTestErrorMessage("");
      setCreateBackTestError(false);
      return true;
    }
  } catch (error) {
    setCreateBackTestError(true);
    setCreateBackTestErrorMessage("Error creating backtest");
    console.error("Error creating backtest:", error);
  }
};

export const listOfMathSymbols: MathSymbolType[] = [
  { id: "logic_symbol_>", type: "logic_symbol", MathSymbols: ">" },
  { id: "logic_symbol_<", type: "logic_symbol", MathSymbols: "<" },
  { id: "logic_symbol_>=", type: "logic_symbol", MathSymbols: ">=" },
  { id: "logic_symbol_<=", type: "logic_symbol", MathSymbols: "<=" },
  { id: "logic_symbol_==", type: "logic_symbol", MathSymbols: "==" },
  { id: "logic_symbol_!==", type: "logic_symbol", MathSymbols: "!==" },
  { id: "logic_symbol_+", type: "compute_symbol", MathSymbols: "+" },
  { id: "logic_symbol_-", type: "compute_symbol", MathSymbols: "-" },
  { id: "logic_symbol_*", type: "compute_symbol", MathSymbols: "*" },
  { id: "logic_symbol_/", type: "compute_symbol", MathSymbols: "/" },
];

export const listOfContectLogic: ContectLogicType[] = [
  { id: "contect_logic_and", type: "contect_logic", ContectLogic: "and" },
  { id: "contect_logic_or", type: "contect_logic", ContectLogic: "or" },
];

export const listOfSignal: SignalType[] = [
  { id: "signal_bullish", type: "signal" ,value : "100"},
  { id: "signal_neutral", type: "signal" ,value: "0"},
  { id: "signal_bearish", type: "signal" ,value: "-100"},
];


