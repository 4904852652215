import { Button } from "@mui/material";
import Bg1 from "@/static/images/home/bg1.png";
import Banner1 from "@/static/images/home/banner1.png";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Partner from "./components/Partner";
import LinearGradientTitle from "./components/linear-gradient-title";
import CorrProtocol from "./corr-protocol";
import AiPowered from "./ai-powered";
import TradingInspirations from "./trading-inspirations";
import TrustByOver from "./trust-by-over";
import JoinUs from "./join-us";
import { useNavigate } from "react-router-dom";
import Footer from "@/components/footer";
import { useEffect, useRef } from "react";
import LightSvg from "@/static/images/home/light.svg";
import "./index.css";
import Line from "./p5line/line";
import Header from "@/components/landingpageHeader";
import { useStore } from "@/store";
const Home: React.FC = () => {
  const navigate = useNavigate();

  const{ accessToken } = useStore();
  
  const handleLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // console.log(entries);

        entries.forEach((item) => {
          if (item.isIntersecting) {
            item.target.classList.add("fade-in");
          }
        });
      },
      { threshold: 0.3 }
    );
    document.querySelectorAll(".slide-element").forEach((item) => {
      observer.observe(item);
    });
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (accessToken) {
      navigate("/bt-studio");
    }
  }, []);

  useEffect(() => {
    const header = document.querySelector(".home_header");
    const elements = document.querySelectorAll(".scroll-effect");

    const handleScroll = () => {
      if (window.scrollY > 150) {
        header?.classList.add("scrolled");
        elements.forEach((element) => {
          element.classList.add("fade-out");
        });
      } else {
        header?.classList.remove("scrolled");
        elements.forEach((element) => {
          element.classList.remove("fade-out");
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const joinUsRef = useRef<HTMLDivElement | null>(null);
  const handleScrollToJoinUs = () => {
    joinUsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          backgroundColor: "#151515",
          zIndex: "0",
        }}
      >
        <Header />
        <div style={{ overflowX: "hidden" }}>
          {/* <div className=" absolute w-full z-[-1] overflow-hidden top-[150px] sm:top-[220px] md:top-[270px]  max-sm:h-[596px]">
            <span className="scroll-effect">
              <Line />
            </span>
          </div> */}
          <div className="flex justify-center top-0 left-0 absolute w-full scroll-effect cursor-default">
            <img className="w-full h-[500px]" alt="" src={LightSvg} />
          </div>

          <main className=" relative px-5 max-md:px-4 pb-24 max-w-[1280px] mx-auto flex flex-col justify-center items-center text-center">
            {/* <Button
              sx={{
                backgroundColor: "#3A3A3A33",
                width: "210px",
                borderRadius: "20px",
                fontSize: "16px",
              }}
              disabled
            >
              <span className=" text-white">Corr Protocol V1</span>
              <KeyboardArrowRightIcon
                sx={{ color: "#E7E9EA", fontSize: "20px" }}
              />
            </Button> */}
          
            <div className="md:mt-8 mt-4 z-0 flex flex-col justify-center items-center text-center scroll-effect">
              <div className="text-2xl md:text-6xl font-normal mt-10">
                Opening a New Era
              </div>
              <LinearGradientTitle className="mt-1 md:mt-3 text-4xl md:!text-[76px] font-normal">
                AI DeQuant Trading
              </LinearGradientTitle>
              <p className="text-[#AAAAAA] text-xl mt-4 md:mt-8">
                Corr Ai - A Decentralized Quantitative Trading Ecosystem
              </p>
              <div className="flex max-md:flex-col mt-4 md:mt-8">
                <Button
                  sx={{
                    borderRadius: 20,
                    width: 200,
                    boxSizing: "border-box",
                    backgroundColor: "#FFAC06",
                    '&:hover': {
                      backgroundColor: '#FFAC0690'
                    }
                  }}
                  size="large"
                  variant="contained"
                  onClick={handleLogin}
                >
                 Join CorrAI Beta
                </Button>
                <Button
                  sx={{
                    borderRadius: 20,
                    color: "#E7E9EA", 
                    borderColor: "#5A5A5A",
                    width: 200,
                    '&:hover': {
                      backgroundColor: '#FFAC0690',
                      borderColor: '#FFAC0690'
                    }
                  }}
                  className="md:!ml-4 max-md:!mt-4"
                  size="large"
                  variant="outlined"
                  onClick={handleScrollToJoinUs} // Add this handler
                >
                  Book a demo
                </Button>
              </div>
            </div>

            <div className="bannan_1_box">
              <img
                className="w-full h-auto max-md:mt-14 md:mt-[90px] z-10 bannan_1"
                src={Banner1}
                alt=""
              />
            </div>
            <Partner className="md:mt-10 md:mb-10 mt-5 mb-5" />
            <div className=" relative w-[100vw]">
              <div
                style={{ left: "calc((100vw - 100%) / 2)" }}
                className=" absolute top-0 bg-[#E7E9EA1A] h-[1px] w-[100vw]"
              ></div>
            </div>
            <TradingInspirations />
            <AiPowered />
            <CorrProtocol />
            <TrustByOver />
            <div ref={joinUsRef}>
              <JoinUs />
            </div>
            {/* <div className='light absolute w-[500px] h-[500px] left-auto right-auto bg-[#E7E9EA80] backdrop-blur-[600px]'></div> */}
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};
export default Home;
