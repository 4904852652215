import React, { createContext, useContext, useState, ReactNode } from 'react';

interface DryRunContextType {
  isMockCreate: boolean;
  setIsMockCreate: (value: boolean) => void;
  dryRunName: string;
  setDryRunName: (value: string) => void;
}

const DryRunContext = createContext<DryRunContextType | undefined>(undefined);

export const DryRunProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isMockCreate, setIsMockCreate] = useState(false);
  const [dryRunName, setDryRunName] = useState('');

  return (
    <DryRunContext.Provider value={{ 
      isMockCreate, 
      setIsMockCreate,
      dryRunName,
      setDryRunName
    }}>
      {children}
    </DryRunContext.Provider>
  );
};

export const useDryRun = () => {
  const context = useContext(DryRunContext);
  if (context === undefined) {
    throw new Error('useDryRun must be used within a DryRunProvider');
  }
  return context;
}; 