import React from "react";
import { ThemeProvider } from "@mui/material";
import theme from "./global/theme";
import Router from "./router";
import { Toaster } from "react-hot-toast";
import ProtectedRouter from "@/router/utils/ProtectedRouter";
import MessageProvider from "./components/basic/Message";
import { useNavigate } from "react-router-dom";
import { injectNavigate } from "./utils";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GlobalContext } from "./global/context";
import Mitt from "mitt";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import { DryRunProvider } from './context/DryRunContext';
dayjs.extend(utc);
const App: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = new QueryClient();
  const eventBus = Mitt();
  injectNavigate(navigate);
  if (process.env.NODE_ENV === 'production') {
    console.log = function () {}; // 生产环境禁用 console.log
  }
  return (
    <QueryClientProvider client={queryClient}>
      <MessageProvider>
        <GlobalContext.Provider value={{ eventBus }}>
          <DryRunProvider>
            <ThemeProvider theme={theme}>
              <ProtectedRouter>
                <Router></Router>
              </ProtectedRouter>
              <Toaster />
            </ThemeProvider>
          </DryRunProvider>
        </GlobalContext.Provider>
      </MessageProvider>
    </QueryClientProvider>
    // <AuthProvider>
    //   <Header />
    //   <ErrorBoundary>
    //     <Routes>
    //       <Route path='/login' element={<Login />} />
    //       <Route path='/signup' element={<SignUp />} />
    //       <Route path='/' element={<Home />} />
    //       <Route
    //         path='/strategy-markets'
    //         element={
    //           <ProtectedRoute>
    //             <StrategyMarkets />
    //           </ProtectedRoute>
    //         }
    //       />
    //       <Route
    //         path='/dashboards'
    //         element={
    //           <ProtectedRoute>
    //             <Dashboards />
    //           </ProtectedRoute>
    //         }
    //       />
    //       <Route
    //         path='/bt-studio/*'
    //         element={
    //           <ProtectedRoute>
    //             <BtStudio />
    //           </ProtectedRoute>
    //         }
    //       />
    //     </Routes>
    //   </ErrorBoundary>
    // </AuthProvider>
  );
};

export default App;
