import { CoinItem } from "@/pages/BtStudio/components/ChooseBar/ChooseBar";
import request from ".";
import axios from "axios";
import { IListResponseData, IResponseData, PageParams } from "./model/request";
import { StrategyItem, StrategyPairItem } from "./model/coin";
export interface IListInterval {
  indId: number;
  intervals: string;
}
/** 币类型 */
export function coinPage(params: PageParams) {
  return request.get<IListResponseData<CoinItem>, IListResponseData<CoinItem>>(
    "/app-api/metadata/coin/page",
    { params }
  );
}
/**币时间筛选条件 */
export function listIntervals() {
  return request.get<
    IResponseData<IListInterval[]>,
    IResponseData<IListInterval[]>
  >("/app-api/metadata/coin/listIntervals");
}
/**price data */
export function listFoctors(params: { coinId: string; interval: string }) {
  return request.get("/app-api/coin/price/listFactors", { params });
}
/**Indicators */
export function listInds(params: { foctorId: string; isPrice: boolean }) {
  return request.get<IResponseData, IResponseData>(
    "/app-api/metadata/coin/listInds",
    { params }
  );
}
/** get price */
export function symbolPrice(
  params: {
    startTime: string;
    endTime: string;
    interval: string;
    coinId: string;
    colName: string;
  },
  signal: AbortSignal
) {
  return request.get<IResponseData, IResponseData>(
    "/app-api/coin/kline/getPrice",
    { params, signal }
  );
}
/** get noprice data  */
export function symbolNoPrice(
  params: {
    startTime: string;
    endTime: string;
    provider: string;
    interval: string;
    factorId: string;
  },
  signal: AbortSignal
) {
  return request.get<IResponseData, IResponseData>(
    "/app-api/coin/none-price/getNonPriceMetricData",
    { params, signal }
  );
}
/** get  */
export function symbolInd(
  params: {
    startTime: string;
    endTime: string;
    interval: string;
    coinId: string;
    colName: string;
    indName: string;
    payload: string;
    indicatorType: string;
    cacheKey: string;
    dataType: string;
    layerId: string;
  },
  signal: AbortSignal
) {
  return request.get<IResponseData, IResponseData>(
    "/app-api/coin/kline/getInd",
    { params, signal }
  );
}

export function getNonPriceDataInd(
  params: {
    startTime: string;
    endTime: string;
    interval: string;
    coinId: string;
    colName: string;
    indName: string;
    payload: string;
    cacheKey: string;
    factorId: string;
    indicatorType: string;
    dataType: string;
    layerId: string;
    provider: string;
  },
  signal: AbortSignal
) {
  return request.get<IResponseData, IResponseData>(
    "/app-api/coin/none-price/getNonPriceInd",
    { params, signal }
  );
}

export function createBackTestv1(data: any) {
  return request.post<IResponseData, IResponseData>(
    "/app-api/coin/strategy/run",
    data
  );
}

export function createBackTest(data: any) {
  return request.post<IResponseData, IResponseData>(
    "/app-api/coin/freqtrade/backtestingV2",
    data
  );
}



export function saveStrategy(data: any) {
  return request.post<IResponseData, IResponseData>(
    "/app-api/metadata/strategy/saveV2",
    data
  );
}
export function uplodaFile(path: string, data: any) {
  return request.post<IResponseData, IResponseData>(
    "/app-api/metadata/oss/upload",
    data,
    {
      params: {
        path,
      },
    }
  );
}
export function strategyList(params: any) {
  return request.get<any, IListResponseData<StrategyItem>>(
    "/app-api/metadata/strategy/page",
    { params }
  );
}
export function strategyListPair(params: PageParams) {
  return request.get<any, IListResponseData<StrategyPairItem>>(
    "/app-api/metadata/strategy/listPair",
    {
      params,
    }
  );
}
// 获取策略详情
export function strategyInfoGet(params: any) {
  return request.get("/app-api/metadata/strategy/get", { params });
}
/** get kline data  */
export function fetchKlineData(params: KlineProps, signal: AbortSignal) {
  return request.get<IResponseData, IResponseData>(
    "/app-api/coin/kline/getKline",
    { params, signal }
  );
}
/** get kline indicators */
export function fetchKlineIndicators() {
  return request.get<IResponseData, IResponseData>(
    "/app-api/metadata/coin/listKlineInds"
  );
}
/** get kline indicators data */
export function fetchKlineIndicatorsData(
  params: KlineIndicatorProps,
  signal: AbortSignal
) {
  return request.get<IResponseData, IResponseData>(
    "/app-api/coin/kline/getKlineInd",
    { params, signal }
  );
}
/** get history backtest record */
export function fetchHistoryRecord() {
  return request.get<IResponseData, IResponseData>(
    "/app-api/metadata/strategy/createHistory"
  );
}
/** chat GPT */
export function fetchChatGPT(params: ChatGPT) {
  return request.post<IResponseData, IResponseData>(
    "/app-api/coin/chat/qa",
    params
  );
}
/** start optimizer */
export function fetchOptimizer(params: any) {
  return request.post<IResponseData, IResponseData>(
    "/app-api/coin/optimizer/run",
    params
  );
}
/** optimizer page */
export function fetchOptimizerList(params: any) {
  return request.get<IResponseData, IListResponseData<OptimizerItem>>(
    "/app-api/metadata/optimizer-config/page",
    { params }
  );
}
/** get optimizer info */
export function fetchOptimizerInfo(params: any) {
  return request.get<IResponseData, IResponseData<any>>(
    "/app-api/metadata/optimizer-config/get",
    { params }
  );
}
/** hot chart */
export function fetchOptimizerHot(params: any) {
  return request.post<IResponseData, IResponseData<any>>(
    `/app-api/job/optimizer/getData?oid=${params.oid}`
  );
}
/** layer ind */
export function fetchLayerData(params: any, signal: AbortSignal) {
  return request.post<IResponseData, IResponseData<any>>(
    `/app-api/coin/layer/data`,
    params,
    {
      signal,
    }
  );
}

/** GetGassnodeApiKey */
export function GetGassnodeApiKey() {
  return request.get<IResponseData, IResponseData<any>>(
    `/app-api/coin/user-data-apikey/getByUserId`
  );
}


/** PostGassnodeApiKey */
export function PostGassnodeApiKey(params: any) {
  return request.post<IResponseData, IResponseData<any>>(
    `/app-api/coin/user-data-apikey/create`,
    params
  );
}

/** DeleteGassnodeApiKey */
export function DeleteGassnodeApiKey(params: any) {
  return request.delete<IResponseData, IResponseData<any>>(
    `/app-api/coin/user-data-apikey/delete`,
    { params }
  );
}
export function createDryRun( strategyId:string ) {
  return request.get<IResponseData, IResponseData<any>>(`/app-api/coin/freqtrade/dryrun`, { params: { strategyId } });
}
export function getBotList( strategyId:string ) {
  return request.get<IResponseData, IResponseData<any>>(`/app-api/coin/freqtrade/botdata`, { params: { strategyId } });
}
export function stopDryRun( strategyId:string ) {
  return request.get<IResponseData, IResponseData<any>>(`/app-api/coin/freqtrade/stopdryrun`, { params: { strategyId } });
}

