import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./Header.css"; // Make sure to create and style this CSS file accordingly
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "@mui/material/Skeleton";
import { useStore } from "@/store";

const Header: React.FC = () => {
  const { accessToken, userInfo, loadUserInfo, clearUserInfo } = useStore();
  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {

    if (accessToken && !userInfo) {
      loadUserInfo();
    }

  },[accessToken, userInfo]);

  const logout = () => {
    clearUserInfo();
    window.location.href = "/";
    return;
  };
  
  const goToPortfolio = () => {
    navigate("/portfolio");
    setIsHovered(false);
  };
  
  return (
    <header className="header">
      <div className="logo" style={{ marginLeft: 10, display: "flex" }}>
        {/* <NavLink end> */}
        <img src="/headlogo.svg" alt="corr.ai logo" width={95}/>
        {/* </NavLink> */}
      
      </div>

      <nav className="nav">
        <ul className="nav">
          {/* <li>
            <NavLink
              to="/edgest"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Edgest
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink
              to="/strategy-markets"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Markets
            </NavLink>
          </li> */}
            {/* <li>
            <NavLink
              to="/Dryrun"
              className={({ isActive }) => (isActive ? "active-link" : "")}              
            >
              Dry Run
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="/dashboards"
              className={({ isActive }) => (isActive ? "active-link" : "")}              
            >
              My strategies
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="/optimization"
              className={({ isActive }) => (isActive ? "active-link" : "")}              
            >
              My Optimization
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="/bt-studio"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Backtest Studio
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="/ranking"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Rankings
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink
              to="/rewards"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              rewards
            </NavLink>
          </li> */}

          {userInfo ? (
            <div
              className="hover_effect_logout"
              style={{
                textDecoration: "none",
                fontSize: 13,
                position: "relative", // To position the dropdown relative to this div
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: "#FFAC06",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "25%",
                    color: "#000000",
                  }}
                >
                  {userInfo.email ? userInfo.email.slice(0, 1).toUpperCase() : "U"}
                </span>
                <FontAwesomeIcon
                  style={{ marginLeft: 10 }}
                  icon={faChevronDown}
                />
              </div>

              <div
                className={`hover_logo ${
                  isHovered ? "hover_logo_expanded" : ""
                }`}
              >
                <div className="hover_logo_content">
                  {/* <div
                    onClick={goToPortfolio}
                    className="hover_logo_item"
                  >
                    Profile
                  </div>
                  <div
                    onClick={() => {
                      navigate("/pricing");
                    }}
                    className="hover_logo_item"
                  >
                    Pricing
                  </div> */}
                  <div
                    onClick={() => {
                      logout();
                    }}
                    className="hover_logo_item"
                  >
                    Log out
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Skeleton
              variant="rounded"
              animation="wave"
              width="46px"
              height="25px"
              sx={{ bgcolor: "grey.900", margin: 1, opacity: 0.5 }}
            />
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
