import { StateCreator } from "zustand";
import {
  FoctorBase,
  FoctorIndicator,
  actionType,
  FoctorState,
  StoreState,
} from "@/types";
import { produce } from "immer";
import { findDeepestLayer, getChartDataName } from "@/utils/tool";

export const createFoctorsSlice: StateCreator<
  StoreState,
  [],
  [],
  FoctorState
> = (set, get) => ({
  // Variables
  foctors: [],
  data: {},
  actionType: {} as actionType,
  chartData: {},
  // Methods

  // 新增foctor
  addFoctor: (foctor: FoctorBase) => {
    set(
      produce((state: FoctorState) => {
        state.foctors.push(foctor);
        state.actionType = {
          foctorId: foctor.foctor_id,
          action: "add",
          type: "foctor",
        };
      })
    );
  },

  // 删除foctor
  deleteFoctor: (foctorId) =>
    set(
      produce((state: FoctorState) => {
        const foctorIndex = state.foctors.findIndex(
          (foctor) => foctor.foctor_id === foctorId
        );
        const foctor = state.foctors[foctorIndex];
        if (foctorIndex !== -1) {
          state.foctors.splice(foctorIndex, 1);
          const name = `${get().symbolLabel(foctor?.symbol)} ${
            foctor?.foctor
          } ${foctor?.interval}`;
          for (let key in state.chartData) {
            if (key.startsWith(name)) {
              // state.deleteChartData(key);
              delete state.chartData[key];
            }
          }
          for (let key in state.data) {
            if (key.startsWith(getChartDataName(foctor))) {
              console.log(getChartDataName(foctor), key, "dedata");
              // state.deleteData(key);
              delete state.data[key];
            }
          }
        }
      })
    ),
  // 覆盖foctor
  updateFoctors: (foctors) =>
    set(
      produce((state) => {
        state.foctors = foctors;
      })
    ),
  // 新增layer
  addLayer: (foctorId, newLayer) =>
    set(
      produce((state: FoctorState) => {
        const foctor = state.foctors.find(
          (fb: FoctorBase) => fb.foctor_id === foctorId
        );
        if (foctor) {
          foctor.factor_layer = addLayerRecursively(
            foctor.factor_layer || [],
            newLayer
          );
          // 清空foctor_indicator
          foctor.foctor_indicator = [];
          state.actionType = { foctorId, action: "add", type: "layer" };
        }
      })
    ),

  // 删除layer, 先更新layer,然后触发请求，获取到数据后删除数据，更新为新数据
  deleteLayer: (foctorId, targetLayerId) =>
    set(
      produce((state: FoctorState) => {
        const foctor = state.foctors.find(
          (fb: FoctorBase) => fb.foctor_id === foctorId
        );
        if (foctor) {
          const newLayer = foctor.factor_layer
            ?.map((layer) => deleteLayerRecursive(layer, targetLayerId))
            .filter((layer) => layer !== undefined) as FoctorIndicator[];
          if (newLayer.length === 0) {
            delete foctor.factor_layer;
          } else {
            foctor.factor_layer = newLayer;
          }
          // 清空foctor_indicator
          foctor.foctor_indicator = [];
          const name = `${get().symbolLabel(foctor?.symbol)} ${
            foctor?.foctor
          } ${foctor?.interval}`;

          for (let key in state.chartData) {
            if (key.startsWith(name)) {
              // state.deleteChartData(key);
              delete state.chartData[key];
            }
          }
          for (let key in state.data) {
            if (key.startsWith(getChartDataName(foctor))) {
              delete state.data[key];
            }
          }
          state.actionType = { foctorId, action: "delete", type: "layer" };
        }
      })
    ),
  // 更新layer,补全接口返回的数据
  updateLayer: (foctorId, layerId, colName) =>
    set(
      produce((state: FoctorState) => {
        const foctor = state.foctors.find((f) => f.foctor_id === foctorId);
        const lastLayer = findDeepestLayer(foctor);
        if (lastLayer && "currentLayerId" in lastLayer) {
          lastLayer.currentLayerId = layerId;
          lastLayer.currentLayervReturn = colName;
        }
      })
    ),

  // 新增indicator
  addIndicator: (foctorId: string, newIndicator: FoctorIndicator) =>
    set(
      produce((state: FoctorState) => {
        const foctor = state.foctors.find((f) => f.foctor_id === foctorId);
        if (foctor) {
          foctor.foctor_indicator = foctor?.foctor_indicator || [];
          foctor!.foctor_indicator.push(newIndicator);
          state.actionType = { foctorId, action: "add", type: "indicator" };
        }
      })
    ),

  // 更新indicator
  updateIndicator: (foctorId, indicatorId, data) =>
    set(
      produce((state: FoctorState) => {
        const foctor = state.foctors.find(
          (fb: FoctorBase) => fb.foctor_id === foctorId
        );
        if (foctor) {
          foctor.foctor_indicator = foctor.foctor_indicator?.map((indicator) =>
            indicator.id === indicatorId ? { ...indicator, ...data } : indicator
          );
        }
      })
    ),

  // 删除foctor下的indicator
  deleteIndicator: (foctorId, indicator) =>
    set(
      produce((state: FoctorState) => {
        const foctor = state.foctors.find((f) => f.foctor_id === foctorId);
        if (foctor) {
          const indicatorIndex = foctor.foctor_indicator?.findIndex(
            (ind) => ind.id === indicator.id
          );
          if (indicatorIndex !== -1 && indicatorIndex !== undefined) {
            foctor.foctor_indicator?.splice(indicatorIndex, 1);
            // 同步删除数据
            indicator.vreturn.forEach((v, index) => {
              const deleteDataName = getChartDataName(indicator, index);
              delete state.data[deleteDataName];
            });
            const indicatorKey = `${get().symbolLabel(indicator?.symbol)} ${
              indicator?.foctor
            } ${indicator?.interval} ${indicator?.label} ${
              indicator?.payload?.timeperiod
            }`;
            // state.deleteChartData(indicatorKey)
            delete state.chartData[indicatorKey];
          }
        }
      })
    ),

  // 添加数据
  addData: (newData, foctor_id) =>
    set(
      produce((state: FoctorState) => {
        if (foctor_id) {
          for (const key in state.data) {
            if (key.startsWith(foctor_id)) {
              delete state.data[key];
            }
          }
        }
        Object.assign(state.data, newData);
      })
    ),

  // 删除数据
  deleteData: (key) =>
    set(
      produce((state: FoctorState) => {
        delete state.data[key];
      })
    ),
  // 删除Foctor下所有data
  deleteFoctorAllData: (foctor) =>
    set(
      produce((state: FoctorState) => {
        const name = getChartDataName(foctor);
        for (let key in state.data) {
          if (key.startsWith(name)) get().deleteData(key);
        }
      })
    ),
  // 删除所有数据
  deleteAllData: () =>
    set(
      produce((state: FoctorState) => {
        state.data = {};
        state.chartData = {};
      })
    ),
  setActionType: (actionType) =>
    set(
      produce((state) => {
        state.actionType = actionType;
      })
    ),
  addChartData: (newData) =>
    set(
      produce((state: FoctorState) => {
        Object.assign(state.chartData, newData);
      })
    ),
  deleteChartData: (key) =>
    set(
      produce((state: FoctorState) => {
        delete state.chartData[key];
      })
    ),
  resetFoctorSliceState: () => {
    set(
      produce((state) => {
        state.foctors = [];
        state.chartData = {};
        state.data = {};
        state.actionType = {};
      })
    );
  },
});

// 递归添加 Layer
const addLayerRecursively = (
  foctorLayer: FoctorIndicator[],
  newLayer: FoctorIndicator
): FoctorIndicator[] => {
  if (foctorLayer.length === 0) {
    return [newLayer]; // 如果没有层级，直接添加新层
  }

  // 否则递归添加到每一层的 factor_layer 中
  return foctorLayer.map((layer) => ({
    ...layer,
    factor_layer: addLayerRecursively(layer.factor_layer || [], newLayer),
  }));
};
const deleteLayerRecursive = (
  layer: FoctorIndicator,
  id: string
): FoctorIndicator | undefined => {
  // 如果当前节点是目标节点，返回 undefined 表示删除
  if (layer.id === id) {
    return undefined;
  }

  // 如果存在子节点，递归处理子节点
  if (layer.factor_layer) {
    layer.factor_layer = layer.factor_layer
      .map((child) => deleteLayerRecursive(child, id)) // 递归处理子节点
      .filter((child): child is FoctorIndicator => child !== undefined); // 过滤掉 undefined
  }

  // 返回当前节点
  return layer;
};
