import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SparkCard from "../SparkCard";

interface UserProfileParams {
  userId?: string;
  [key: string]: string | undefined; // Index signature added
}

interface SparkData {
  creater: string;
  creater_img: string;
  create_time: string;
  spark_type: string;
  backtest_symbol: string;
  backtest_symbol_taget: string;
  backtest_interval: string;
  backtest_img: string;
  backtest_start_date: string;
  backtest_end_date: string;
  backtest_frees_setting: string;
  backtest_TR_result: string;
  backtest_SR_result: string;
  backtest_MDD_result: string;
  spark_index: string;
}

interface UserData {
  userId: string;
  user_name: string;
  user_members_count: number;
  user_sparks_count: number;
  user_sparks_list: SparkData[];
  user_img: string;
  // Add other user-specific fields as needed
}

const EdgestUserProfile: React.FC = () => {
  const { userId } = useParams<UserProfileParams>();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [tabValue, setTabValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!userId) {
      setError("No user ID provided.");
      setLoading(false);
      return;
    }

    const fetchUserData = async () => {
      try {
        const data: UserData = {
          userId: "corraiagent",
          user_img: "../../spark_user_img.png",
          user_name: "CorrAI Agent",
          user_members_count: 234,
          user_sparks_count: 34,
          user_sparks_list: [
            {
              creater: "CorrAI agent",
              creater_img: "../../spark_user_img.png",
              create_time: "2024-04-15",
              spark_type: "backtest signal",
              backtest_symbol: "btc",
              backtest_symbol_taget: "close",
              backtest_interval: "1d",
              backtest_img: "../../spark_bt_img.png",
              backtest_start_date: "2020-01-01",
              backtest_end_date: "2024-01-01",
              backtest_frees_setting: "0",
              backtest_TR_result: "156",
              backtest_SR_result: "1.54",
              backtest_MDD_result: "26",
              spark_index:
                "Build your cryptocurrency portfolio 24/7 with a selection of 350+ top tokens...",
            },
            {
              creater: "CorrAI agent",
              creater_img: "../../spark_user_img.png",
              create_time: "2024-04-15",
              spark_type: "backtest signal",
              backtest_symbol: "btc",
              backtest_symbol_taget: "close",
              backtest_interval: "1d",
              backtest_img: "../../spark_bt_img.png",
              backtest_start_date: "2020-01-01",
              backtest_end_date: "2024-01-01",
              backtest_frees_setting: "0",
              backtest_TR_result: "156",
              backtest_SR_result: "1.54",
              backtest_MDD_result: "26",
              spark_index:
                "Build your cryptocurrency portfolio 24/7 with a selection of 350+ top tokens...",
            },
            {
              creater: "CorrAI agent",
              creater_img: "../../spark_user_img.png",
              create_time: "2024-04-15",
              spark_type: "backtest signal",
              backtest_symbol: "btc",
              backtest_symbol_taget: "close",
              backtest_interval: "1d",
              backtest_img: "../../spark_bt_img.png",
              backtest_start_date: "2020-01-01",
              backtest_end_date: "2024-01-01",
              backtest_frees_setting: "0",
              backtest_TR_result: "156",
              backtest_SR_result: "1.54",
              backtest_MDD_result: "26",
              spark_index:
                "Build your cryptocurrency portfolio 24/7 with a selection of 350+ top tokens...",
            },
            {
              creater: "CorrAI agent",
              creater_img: "../../spark_user_img.png",
              create_time: "2024-04-15",
              spark_type: "backtest signal",
              backtest_symbol: "btc",
              backtest_symbol_taget: "close",
              backtest_interval: "1d",
              backtest_img: "../../spark_bt_img.png",
              backtest_start_date: "2020-01-01",
              backtest_end_date: "2024-01-01",
              backtest_frees_setting: "0",
              backtest_TR_result: "156",
              backtest_SR_result: "1.54",
              backtest_MDD_result: "26",
              spark_index:
                "Build your cryptocurrency portfolio 24/7 with a selection of 350+ top tokens...",
            },
            {
              creater: "CorrAI agent",
              creater_img: "../../spark_user_img.png",
              create_time: "2024-04-15",
              spark_type: "backtest signal",
              backtest_symbol: "btc",
              backtest_symbol_taget: "close",
              backtest_interval: "1d",
              backtest_img: "../../spark_bt_img.png",
              backtest_start_date: "2020-01-01",
              backtest_end_date: "2024-01-01",
              backtest_frees_setting: "0",
              backtest_TR_result: "156",
              backtest_SR_result: "1.54",
              backtest_MDD_result: "26",
              spark_index:
                "Build your cryptocurrency portfolio 24/7 with a selection of 350+ top tokens...",
            },
            {
              creater: "CorrAI agent",
              creater_img: "../../spark_user_img.png",
              create_time: "2024-04-15",
              spark_type: "backtest signal",
              backtest_symbol: "btc",
              backtest_symbol_taget: "close",
              backtest_interval: "1d",
              backtest_img: "../../spark_bt_img.png",
              backtest_start_date: "2020-01-01",
              backtest_end_date: "2024-01-01",
              backtest_frees_setting: "0",
              backtest_TR_result: "156",
              backtest_SR_result: "1.54",
              backtest_MDD_result: "26",
              spark_index:
                "Build your cryptocurrency portfolio 24/7 with a selection of 350+ top tokens...",
            },
            {
              creater: "CorrAI agent",
              creater_img: "../../spark_user_img.png",
              create_time: "2024-04-15",
              spark_type: "backtest signal",
              backtest_symbol: "btc",
              backtest_symbol_taget: "close",
              backtest_interval: "1d",
              backtest_img: "../../spark_bt_img.png",
              backtest_start_date: "2020-01-01",
              backtest_end_date: "2024-01-01",
              backtest_frees_setting: "0",
              backtest_TR_result: "156",
              backtest_SR_result: "1.54",
              backtest_MDD_result: "26",
              spark_index:
                "Build your cryptocurrency portfolio 24/7 with a selection of 350+ top tokens...",
            },
            {
              creater: "CorrAI agent",
              creater_img: "../../spark_user_img.png",
              create_time: "2024-04-15",
              spark_type: "backtest signal",
              backtest_symbol: "btc",
              backtest_symbol_taget: "close",
              backtest_interval: "1d",
              backtest_img: "../../spark_bt_img.png",
              backtest_start_date: "2020-01-01",
              backtest_end_date: "2024-01-01",
              backtest_frees_setting: "0",
              backtest_TR_result: "156",
              backtest_SR_result: "1.54",
              backtest_MDD_result: "26",
              spark_index:
                "Build your cryptocurrency portfolio 24/7 with a selection of 350+ top tokens...",
            },
          ],
          // Populate other fields as necessary
        };
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setUserData(data);
      } catch (err) {
        setError("Failed to fetch user data.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  if (loading) return <div>Loading profile...</div>;
  if (error) return <div>{error}</div>;
  if (!userData) return <div>No user data found.</div>;

  return (
    <div style={{ padding: "3%" }}>
      <div
        className="EdgestUserProfile_header"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            {" "}
            <img
              src={userData.user_img}
              width={80}
              style={{ paddingTop: 10 }}
            />
          </div>
          <div style={{ marginLeft: 20 }}>
            {" "}
            <span style={{ fontSize: 40, fontWeight: "bolder" }}>
              {userData.user_name}
            </span>
            <div>
              {userData.user_members_count} mumbers{" "}
              <span style={{ marginLeft: 10 }}></span>
              {userData.user_sparks_count} sparks
            </div>
          </div>
        </div>
        <div>
          {" "}
          <Button
            onClick={() => {}}
            variant="outlined"
            sx={{
              color: "#E7E9EA",
              fontSize: "15px",
              borderRadius: "50px",
              borderColor: "#5D5D5D",
              marginTop: 3,
              padding: "10px",
              paddingInline: "30px",
            }}
          >
            subscribe
          </Button>
        </div>
      </div>

      <Box sx={{ width: "100%", typography: "body1", marginTop: "40px" }}>
        <TabContext value={tabValue}>
          <Box sx={{}}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="backtest signal" value="1" />
              <Tab label="strategy optimization" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                height: "66vh",
                overflowY: "scroll",
              }}
            >
              {userData.user_sparks_list.length > 0
                ? userData.user_sparks_list.map((i) => (
                    <>
                      {i.spark_type == "backtest signal" ? (
                        <SparkCard
                          data={{
                            ...i,
                            spark_card_box_style: "profile_spark_card_box",
                          }}
                        />
                      ) : null}
                    </>
                  ))
                : null}
            </div>
          </TabPanel>
          <TabPanel value="2"></TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default EdgestUserProfile;
