import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import darkTheme from "highcharts/themes/dark-unica";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dayjs from "dayjs";

// Initialize dark theme
darkTheme(Highcharts);

const Dryruninfo: React.FC = () => {
  const { strategyName } = useParams<{ strategyName: string }>();
  const [summary, setSummary] = useState<any>(null);
  const [trades, setTrades] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetch("/summary.json")
      .then((response) => response.json())
      .then((data) => {
        console.log("Summary Data:", data);
        setSummary(data.data);
      })
      .catch((error) => {
        console.error("Error loading summary:", error);
      });
  }, [strategyName]);

  useEffect(() => {
    fetch("/trades.json")
      .then((response) => response.json())
      .then((data) => {
        if (strategyName && data.strategy) {
          const strategyTrades = data.strategy[strategyName]?.trades;
          setTrades(strategyTrades);
        }
      })
      .catch((error) => {
        console.error("Error loading trades:", error);
      });
  }, [strategyName]);

  if (!summary) return <div>Loading...</div>;

  // Base options for Highcharts with a transparent background
  const chartBaseOptions = {
    chart: {
      backgroundColor: "transparent",
      style: {
        fontFamily: "Inter, sans-serif",
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      itemStyle: {
        color: "#E7E9EA",
        fontWeight: "400",
      },
    },
    tooltip: {
      backgroundColor: "transparent",
      borderColor: "#404040",
      style: {
        color: "#E7E9EA",
      },
    },
  };

  // Calculate cumulative profit data
  const cumulativeProfitData = summary.daily_profit.reduce(
    (acc: any[], curr: [string, number], index: number) => {
      const cumulative =
        index === 0 ? summary.starting_balance + curr[1] : acc[index - 1].value + curr[1];

      return [
        ...acc,
        {
          date: curr[0],
          value: cumulative,
        },
      ];
    },
    []
  );

  const cumulativeProfitOptions = {
    ...chartBaseOptions,
    chart: {
      ...chartBaseOptions.chart,
      type: "area",
      height: 300,
    },
    title: {
      text: "Cumulative Profit",
      style: { color: "#E7E9EA", fontSize: "16px", fontWeight: "500" },
    },
    xAxis: {
      categories: cumulativeProfitData.map((item: { date: string; value: number }) => item.date),
      labels: { style: { color: "#888" } },
      gridLineColor: "#262626",
      lineColor: "#404040",
    },
    yAxis: {
      title: { text: "Balance (USDT)", style: { color: "#888" } },
      labels: { style: { color: "#888" } },
      gridLineColor: "#262626",
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.color("#00C49F").setOpacity(0.3).get()],
            [1, Highcharts.color("#00C49F").setOpacity(0).get()],
          ],
        },
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        threshold: null,
      },
    },
    series: [
      {
        name: "Balance",
        color: "#00C49F",
        data: cumulativeProfitData.map((item: { date: string; value: number }) => item.value),
      },
    ],
  };

  const dailyProfitOptions = {
    ...chartBaseOptions,
    chart: {
      ...chartBaseOptions.chart,
      type: "line",
      height: 300,
    },
    title: {
      text: "Daily Profit",
      style: { color: "#E7E9EA", fontSize: "16px", fontWeight: "500" },
    },
    xAxis: {
      categories: summary.daily_profit.map((item: [string, number]) => item[0]),
      labels: { style: { color: "#888" } },
      gridLineColor: "#262626",
      lineColor: "#404040",
    },
    yAxis: {
      title: { text: "Profit", style: { color: "#888" } },
      labels: { style: { color: "#888" } },
      gridLineColor: "#262626",
    },
    series: [
      {
        name: "Profit",
        color: "#ffac06",
        data: summary.daily_profit.map((item: [string, number]) => item[1]),
      },
    ],
  };

  const winLossOptions = {
    ...chartBaseOptions,
    chart: {
      ...chartBaseOptions.chart,
      type: "pie",
      height: 300,
    },
    title: {
      text: "Win/Loss Distribution",
      style: { color: "#E7E9EA", fontSize: "16px", fontWeight: "500" },
    },
    plotOptions: {
      pie: {
        innerSize: "60%",
        colors: ["#00C49F", "#FF8042"],
        dataLabels: {
          color: "#E7E9EA",
          style: {
            textOutline: "none",
          },
        },
      },
    },
    series: [
      {
        name: "Trades",
        data: [
          ["Wins", summary.wins],
          ["Losses", summary.losses],
        ],
      },
    ],
  };

  const winrateOptions = {
    ...chartBaseOptions,
    chart: {
      ...chartBaseOptions.chart,
      type: "column",
      height: 300,
    },
    title: {
      text: "Daily Winrate",
      style: { color: "#E7E9EA", fontSize: "16px", fontWeight: "500" },
    },
    xAxis: {
      categories: summary.periodic_breakdown.day.map((day: any) => day.date),
      labels: { style: { color: "#888" } },
      gridLineColor: "#262626",
      lineColor: "#404040",
    },
    yAxis: {
      title: { text: "Winrate (%)", style: { color: "#888" } },
      labels: { style: { color: "#888" } },
      gridLineColor: "#262626",
    },
    series: [
      {
        name: "Winrate",
        color: "#8884d8",
        data: summary.periodic_breakdown.day.map((day: any) =>
          Number((day.winrate * 100).toFixed(2))
        ),
      },
    ],
  };

  const formatDate = (dateString: string) => {
    return dayjs(dateString).format("MMM D, YYYY HH:mm:ss");
  };

  return (
    <div
      className="flex flex-col gap-6 p-6 bg-transparent"
      style={{ color: "#E7E9EA", minHeight: "100vh" }}
    >
      {/* Sticky Header */}
      <div className="flex items-center gap-4 sticky top-0 z-50 bg-transparent p-4">
        <Button
          onClick={() => navigate("/Dryrun")}
          startIcon={<ArrowBackIcon />}
          sx={{
            color: "#E7E9EA",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.08)",
            },
          }}
        >
          Back
        </Button>
        <h1 className="text-2xl font-medium">{strategyName} | Dry Run Details</h1>
      </div>

      {/* Summary Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <StatCard title="Total Trades" value={summary.total_trades} />
        <StatCard title="Win Rate" value={`${(summary.winrate * 100).toFixed(2)}%`} />
        <StatCard title="Total Profit" value={`${summary.profit_total_abs.toFixed(2)} USDT`} />
        <StatCard title="Max Drawdown" value={`${(summary.max_drawdown_account * 100).toFixed(2)}%`} />
      </div>

      {/* Cumulative Profit Chart */}
      <div className="rounded-xl p-4 border border-[#2A2E30] shadow-lg mb-6 bg-transparent">
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...cumulativeProfitOptions,
            title: {
              ...cumulativeProfitOptions.title,
              text: "Cumulative Profit",
            },
          }}
        />
      </div>

      {/* Daily Profit Chart */}
      <div className="rounded-xl p-4 border border-[#2A2E30] shadow-lg mb-6 bg-transparent">
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...dailyProfitOptions,
            title: {
              ...dailyProfitOptions.title,
              text: "Daily Profit",
            },
          }}
        />
      </div>

      {/* Win/Loss and Winrate Charts */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="rounded-xl p-4 border border-[#2A2E30] shadow-lg bg-transparent">
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              ...winLossOptions,
              title: {
                ...winLossOptions.title,
                text: "Win/Loss Distribution",
              },
            }}
          />
        </div>
        <div className="rounded-xl p-4 border border-[#2A2E30] shadow-lg bg-transparent">
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              ...winrateOptions,
              title: {
                ...winrateOptions.title,
                text: "Daily Winrate",
              },
            }}
          />
        </div>
      </div>

      {/* Trades History Table */}
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: "transparent",
          border: "1px solid #2A2E30",
          boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
        }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "#E7E9EA", fontWeight: "bold" }}>Pair</TableCell>
              <TableCell sx={{ color: "#E7E9EA", fontWeight: "bold" }}>Entry Time</TableCell>
              <TableCell sx={{ color: "#E7E9EA", fontWeight: "bold" }}>Exit Time</TableCell>
              <TableCell sx={{ color: "#E7E9EA", fontWeight: "bold" }}>Entry Price</TableCell>
              <TableCell sx={{ color: "#E7E9EA", fontWeight: "bold" }}>Exit Price</TableCell>
              <TableCell sx={{ color: "#E7E9EA", fontWeight: "bold" }}>Amount</TableCell>
              <TableCell sx={{ color: "#E7E9EA", fontWeight: "bold" }}>Profit(%)</TableCell>
              <TableCell sx={{ color: "#E7E9EA", fontWeight: "bold" }}>Profit(USDT)</TableCell>
              <TableCell sx={{ color: "#E7E9EA", fontWeight: "bold" }}>Exit Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trades &&
              trades.map((trade: any, index: number) => (
                <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell sx={{ color: "#E7E9EA" }}>{trade.pair}</TableCell>
                  <TableCell sx={{ color: "#E7E9EA" }}>{formatDate(trade.open_date)}</TableCell>
                  <TableCell sx={{ color: "#E7E9EA" }}>{formatDate(trade.close_date)}</TableCell>
                  <TableCell sx={{ color: "#E7E9EA" }}>{trade.open_rate.toFixed(2)}</TableCell>
                  <TableCell sx={{ color: "#E7E9EA" }}>{trade.close_rate.toFixed(2)}</TableCell>
                  <TableCell sx={{ color: "#E7E9EA" }}>{trade.amount}</TableCell>
                  <TableCell
                    sx={{
                      color: trade.profit_ratio > 0 ? "#4CAF50" : "#FF5252",
                      fontWeight: "bold",
                    }}
                  >
                    {(trade.profit_ratio * 100).toFixed(2)}%
                  </TableCell>
                  <TableCell
                    sx={{
                      color: trade.profit_abs > 0 ? "#4CAF50" : "#FF5252",
                      fontWeight: "bold",
                    }}
                  >
                    {trade.profit_abs.toFixed(2)}
                  </TableCell>
                  <TableCell sx={{ color: "#E7E9EA" }}>{trade.exit_reason}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

// StatCard Component
const StatCard: React.FC<{ title: string; value: string | number }> = ({ title, value }) => (
  <div className="rounded-lg p-4 flex flex-col items-center border border-[#2A2E30] shadow-lg bg-transparent">
    <h3 className="text-sm text-gray-400 mb-2 text-center">{title}</h3>
    <div className="text-xl font-semibold">{value}</div>
  </div>
);

export default Dryruninfo;
