import React, { useState, memo } from "react";
import LinearGradientTitle from "../components/linear-gradient-title";
import { Button, InputBase } from "@mui/material";
import joinUsBg from "@/static/images/home/joinus-bg.png";
import axios from "axios";
import toast from "react-hot-toast";

const JoinUs: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const handleSubmit = async () => {
    if (!email) {
      toast.error("Please enter your email.");
      return;
    }
    try {
      setSubmitted(true);
      const response = await axios.post("https://formspree.io/f/mnnqgrzo", {
        email,
      });

      if (response.status === 200) {
        toast.success("Email submitted successfully!");
        setEmail(""); // Clear input after successful submission
      } else {
        toast.error("Failed to submit. Please try again.");
        setSubmitted(false);
      }
    } catch (error) {
      console.error("Error submitting email:", error);
      toast.error("Failed to submit. Please try again.");
      setSubmitted(false);
    }
  };

  return (
    <>
      <section className="md:mt-44 mt-24 w-full relative aspect-[1215/412] max-sm:px-4">
        <img
          src={joinUsBg}
          className="absolute z-[-1] w-full h-full object-cover top-0 left-0"
          alt=""
        />
        <div className="text-center h-full flex justify-center items-center flex-col">
          <LinearGradientTitle className="text-6xl md:!text-[76px] font-medium">
            Join US
          </LinearGradientTitle>
          <div className="md:text-3xl text-xl">
            to collaboratively build the future of
          </div>
          <div className="md:text-3xl text-xl">AI DeQuant finance.</div>
        </div>
      </section>

      <section
        className="md:py-10 max-sm:mt-5 p-5 md:px-20 rounded-xl flex max-md:flex-col md:items-center justify-between w-full"
        style={{
          background:
            "linear-gradient(90deg, rgba(26, 26, 26, 0.8) 0%, rgba(20, 20, 20, 0.72) 29.5%, rgba(20, 20, 20, 0.72) 70.5%, rgba(26, 26, 26, 0.8) 100%)",
        }}
      >
        <div className="md:text-left flex-1 md:max-w-[373px]">
          <div className="font-medium md:text-4xl sm:text-2xl">
            Subscribe to our list
          </div>
          <div className="text-xs">
            for product whitelisting , please leave your email , we will get in
            touch with you soon. our email address :
            <span style={{ color: "#FFAC06" }}>info@corr.ai</span>
          </div>
        </div>
        <div className="bg-[#0A0A0ACC] max-md:mt-4 border aspect-[536_/_71] border-[#2A2A2A] rounded-xl flex w-full md:w-[350px] lg:w-[536px] p-1 ml-5">
          <InputBase
            sx={{ border: "none", pl: 2, outline: "none" }}
            fullWidth
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            sx={{
              width: "163px",
              height: "100%",
              color: "#E7E9EA",
              background: "#1A1A1A",
            }}
            className="w-[163px] h-full"
            onClick={handleSubmit}
            disabled={submitted}
          >
            {submitted ? "Submitted" : "Submit"}
          </Button>
        </div>
      </section>
    </>
  );
};

export default memo(JoinUs);
